<template>
  <el-dialog
    title="导入失败"
    :close-on-click-modal="false"
    :visible.sync="visible"
    center
    width="800px"
  >
    <el-table :data="showData" style="width: 100%">
      <el-table-column prop="field" label="字段名" width="180"></el-table-column>
      <el-table-column prop="index" label="行列" width="180"></el-table-column>
      <el-table-column prop="msg" label="错误信息"></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { isEmail, isMobile } from "@/utils/validate";
import Qs from "qs";
let lodash = require("lodash");
export default {
  props: ["showData"],
  data() {
    var validateMobile = (rule, value, callback) => {
      if (!isMobile(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      visible: false,
      roleList: [],
      dataRule: {},
    };
  },
  methods: {
    init(id) {
      this.visible = true;
      console.log(this.showData);
    },
  },
};
</script>
